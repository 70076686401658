<template>
    <div>

        <!-- is mobile size show this section -->
        <div v-if="isMobile" class="background-image-mobile d-flex flex-column justify-content-end">

            <div class="container backbtn_container">
                <button class="back-button mt-4 mb-4 mx-2" @click="goBack">
                    <i class="fas fa-arrow-left"></i>
                </button>
            </div>

            <!-- container of all -->
            <div>
                <!-- prayer times -->
                <div class="container prayer-times">
                    <div class="d-flex flex-wrap justify-content-center" dir="rtl">

                        <div class="col text-center" v-for="(time, key) in getPrayerTimes" :key="key"
                            :class="activePrayer === key ? 'active-prayer' : ''">
                            <p>
                                {{ key === "Fajr" ? "الفجر" : key === "Dhuhr" ? "الظهر" : key === "Asr" ? "العصر" : key
            ===
            "Maghrib" ? "المغرب" : "العشاء" }}
                            </p>
                            <p>{{ time }}</p>
                        </div>


                    </div>
                </div>
                <!-- end prayer times -->


                <!-- ramadan category -->
                <div class="container category">

                    <!-- image 0 center of the screen -->
                    <div class="d-flex justify-content-center flex-wrap">
                        <div class="col-4 mb-3" @click="openQuran()">
                            <img src="../../../assets/images/quran_category.png"
                                class="img-fluid rounded mx-auto d-block square-image" alt="" />
                        </div>
                    </div>

                    <div class="d-flex justify-content-center flex-wrap">

                        <div class="col-4 mb-3" @click="openPrayerTime()">
                            <img src="../../../assets/images/prayer_times_category.png"
                                class="img-fluid rounded mx-auto d-block square-image" alt="" />
                        </div>

                        <div class="col-4 mb-3" v-for="item in categories" :key="item.id"
                            @click="openRamadanContent(item.id)">
                            <img :src="item.image['en']" class="img-fluid rounded mx-auto d-block square-image"
                                alt="" />
                        </div>

                    </div>
                </div>
                <!-- end ramadan category -->


                <!--  add disclaimer text in center  -->
                <div class="container text-center py-3 disclaimer_text">
                    <p class="text-white">

                        استمتع بمحتوى إسلامي متنوع ومعلومات الصيام اليومية ونصائح على البوابة الرمضانية لتجربة مميزة.
                        رمضان
                        مبارك!

                    </p>
                </div>
                <!--  end  disclaimer text in center  -->
            </div>
            <!-- end container -->


        </div>

        <!-- end mobile section -->



        <!-- desktop size  -->
        <div v-else class="background-image-desktop d-flex flex-column justify-content-end">

            <div class="background-image-mobile mobile-size-in-desktop d-flex flex-column justify-content-end">

                <div class="container backbtn_container">
                    <button class="back-button mt-4 mb-4 mx-2" @click="goBack">
                        <i class="fas fa-arrow-left"></i>
                    </button>
                </div>

                <div class="container prayer-times">
                    <div class="d-flex flex-wrap  justify-content-center" dir="rtl">

                        <div class="col text-center" v-for="(time, key) in getPrayerTimes" :key="key"
                            :class="activePrayer === key ? 'active-prayer' : ''">
                            <p>
                                {{ key === "Fajr" ? "الفجر" : key === "Dhuhr" ? "الظهر" : key === "Asr" ? "العصر" : key
            ===
            "Maghrib" ? "المغرب" : "العشاء" }}
                            </p>
                            <p>{{ time }}</p>
                        </div>


                    </div>
                </div>


                <div class="container py-5" v-if="categories.length != 0">

                    <!-- image 0 center of the screen -->
                    <div class="d-flex justify-content-center flex-wrap">
                        <div class="col-4 mb-3" @click="openQuran()">
                            <img src="../../../assets/images/quran_category.png"
                                class="img-fluid rounded mx-auto d-block square-image" alt="" />
                        </div>
                    </div>

                    <div class="d-flex justify-content-center flex-wrap">

                        <div class="col-4 mb-3" @click="openPrayerTime()">
                            <img src="../../../assets/images/prayer_times_category.png"
                                class="img-fluid rounded mx-auto d-block square-image" alt="" />
                        </div>

                        <div class="col-4 mb-3" v-for="item in categories" :key="item.id"
                            @click="openRamadanContent(item.id)">
                            <img :src="item.image['en']" class="img-fluid rounded mx-auto d-block square-image"
                                alt="" />
                        </div>

                    </div>
                </div>



                <!--  add disclaimer text in center  -->
                <div class="container text-center py-3 disclaimer_text">
                    <p class="text-white">
                        استمتع بمحتوى إسلامي متنوع ومعلومات الصيام اليومية ونصائح على البوابة الرمضانية لتجربة مميزة.
                        رمضان
                        مبارك!
                    </p>
                </div>


            </div>
        </div>
        <!-- end desktop size -->







    </div>
</template>


<script>
import ApiService from '../../../services/api';
import axios from "axios"

export default {
    data() {
        return {
            // Initial window width
            activePrayer: "",
            categories: [],
            windowWidth: window.innerWidth,

        };
    },
    mounted() {
        this.getRamadanCategories();
        this.getActivePrayer();
        window.addEventListener('resize', this.onResize);
    },

    created() {
        this.getLangAndLat();
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {


        goBack() {
            this.$router.push('/');
        },



        getLangAndLat() {
            // axios.get https://ipapi.co/json/
            axios.get('https://ipapi.co/json/').then(response => {
                this.getPrayerTimesAPI(response.data.latitude, response.data.longitude)
            })


        },

        getPrayerTimesAPI(latitude, longitude) {
            // get from http://api.aladhan.com/v1/timings/29-02-2024?latitude=35.56214&longitude=45.40502&method=3
            try {
                axios.get(`https://api.aladhan.com/v1/timings/${new Date().toISOString().slice(0, 10)}?latitude=${latitude}&longitude=${longitude}&method=3`)
                    .then(response => {
                        localStorage.setItem("prayerTimes", JSON.stringify(response.data.data.timings))
                        console.log(response.data.data.timings)
                    }).catch((e) => {
                        console.log(e)
                    })
            } catch (error) {
                const timings = {
                    Fajr: "4:44",
                    Dhuhr: "12:12",
                    Asr: "15:38",
                    Maghrib: "18:15",
                    Isha: "19:36"
                }
                localStorage.setItem("prayerTimes", JSON.stringify(timings))
            }
        },



        getDefaultTimings() {
            return {
                Fajr: "5:08",
                Dhuhr: "12:16",
                Asr: "15:29",
                Maghrib: "17:59",
                Isha: "19:20"
            };
        },



        onResize() {
            this.windowWidth = window.innerWidth;
        },
        imageClicked(n) {
            alert(`Image ${n} clicked!`);
            // Implement your click handling logic here
        },
        openQuran() {
            this.$router.push('/quran');
        },

        openPrayerTime() {
            this.$router.push('/ramadan/prayer-times');
        },

        getRamadanCategories() {
            ApiService.getRamadanCategories().then((response) => {
                this.categories = response.data.data

            })
        },

        openRamadanContent(id) {
            this.$router.push(`/ramadan/category/${id}`);
        },


        getActivePrayer() {

            const storedTimings = localStorage.getItem('prayerTimes');
            const timings = storedTimings ? JSON.parse(storedTimings) : this.getDefaultTimings();

            const prayerTimes = {
                Fajr: timings.Fajr,
                Dhuhr: timings.Dhuhr,
                Asr: timings.Asr,
                Maghrib: timings.Maghrib,
                Isha: timings.Isha
            };

            const now = new Date();
            const currentTime = now.getHours() * 60 + now.getMinutes(); // convert current time to minutes

            let times = Object.entries(prayerTimes).map(([name, time]) => {
                const [hours, minutes] = time.split(':').map(Number);
                return { name, minutes: hours * 60 + minutes };
            });

            times = times.filter(time => time.minutes > currentTime).sort((a, b) => a.minutes - b.minutes);

            if (times.length > 0) {
                this.activePrayer = times[0].name;
                console.log(times);
                return times[0].name;
            } else {
                // When no times are left, it means it's past the last prayer of the day, so the next prayer is Fajr of the next day.
                this.activePrayer = "Fajr";
                console.log("The next prayer time is Fajr of the next day");
                return "Fajr";
            }
        },






    },
    computed: {
        isMobile() {
            // Define a width threshold for mobile devices
            const mobileWidthThreshold = 768; // Adjust as needed
            return this.windowWidth <= mobileWidthThreshold;
        },

        getPrayerTimes() {
            const storedTimings = localStorage.getItem('prayerTimes');
            const timings = storedTimings ? JSON.parse(storedTimings) : this.getDefaultTimings();
            const prayerTimes = {
                Fajr: timings.Fajr,
                Dhuhr: timings.Dhuhr,
                Asr: timings.Asr,
                Maghrib: timings.Maghrib,
                Isha: timings.Isha
            };

            return prayerTimes;
        },



    },
};
</script>


<style scoped>
.background-image-mobile {
    /* Existing styles */
    background-image: url('../../../assets/images/Ramadanbg.png');
    background-size: cover;
    background-position: center;
    min-height: 100vh;

    /* Flexbox styles for alignment */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.background-image-desktop {
    /* Existing styles */
    background-image: url('../../../assets/images/ramadanDesktopBg.png');
    background-size: cover;
    background-position: center;
    min-height: 100vh;

    /* Flexbox styles for alignment */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.mobile-size-in-desktop {
    /* set a size of mobiloe size  */
    height: 100vh;
    max-width: 30%;
    margin: 0 auto;

}

.back-button {
    /* top: 20px;
    left: 20px;
    z-index: 1000; */
    background: #b16d08;
    color: #fff;
    border: none;
    padding: 9px 15px;
    zoom: 80%;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;

}

.backbtn_container {
    position: absolute;
    top: 0px !important;
}



.prayer-times {
    color: white;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Speda' !important;
    zoom: 80% !important;
    margin-bottom: 70px !important;
}

.prayer-times p {
    font-size: 16px !important;
}

.disclaimer_text {
    font-family: 'Speda' !important;
    font-size: 14px !important;
}

.category {
    zoom: 80% !important;
}

.active-prayer {
    color: #f1c40f !important;
}

.grid-layout .row {
    gap: 10px;
}

.square-image {
    /* Adjust as needed to ensure square images */
    width: 90px;
    height: 90px;
    object-fit: cover;
    margin-bottom: 20px !important;
}

.col-4:hover {
    cursor: pointer !important;
}
</style>
